import {getSpendsByPaymentStatus} from "./transactions.util";
import {getProjectResume} from "./planification.utils";

export const calculateEarnValuesKPIs = (project: any, planifications: any[], transactions: any[])=> {
    let sortedPlanifications = planifications
        .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    if (sortedPlanifications.length === 0) {
        return null;
    }

    let latestPlanification = sortedPlanifications[sortedPlanifications.length - 1];
    let filteredTransactions = transactions
        .filter((transaction) => new Date(transaction.date) <= new Date(latestPlanification.endDate));

    let resume = getProjectResume(sortedPlanifications, filteredTransactions);
    let lastPlanification = resume[resume.length - 1];

    let SPI = lastPlanification.accEarnValue / lastPlanification.accPlanifiedEarnValue;
    let CPI = lastPlanification.accEarnValue / lastPlanification.accCostValue;

    let duration = Math.round((new Date(project.endDate).getTime() - new Date(project.startDate).getTime()) / (24 * 60 * 60 * 1000));
    let currentDuration = Math.round((new Date(latestPlanification.endDate).getTime() - new Date(project.startDate).getTime()) / (24 * 60 * 60 * 1000));

    return  {
        SPI,
        CPI,
        duration,
        currentDuration,
        currentDelay: currentDuration * (1 - SPI),
        expectedDuration: duration / SPI,
        accEarnValue: lastPlanification.accEarnValue,
        accEarnValuePercentage: lastPlanification.accEarnValuePercentage,
        accPlanifiedEarnValue: lastPlanification.accPlanifiedEarnValue,
        planifiedEarnValuePercentage: lastPlanification.planifiedEarnValuePercentage,
        planification: lastPlanification
    }
}