import Layout from "../../Layout/Layout.component";
import {AppContext} from "../../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import {calculateControlSheetData} from "../../utils/controlSheet.utils";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../../utils/number.utils";
import InfoCard from "../../Components/InfoCard.component";

const ControlSheetDashboardPage = () => {

    const { transactions, controlSheets} = useContext(AppContext);

    const [data, setData] = useState<any[]>([]);
    const [resume, setResume] = useState<any>({});

    useEffect(() => {
        if (controlSheets.length > 0) {
            let datum: any = controlSheets.map((cs: any) => {
               return calculateControlSheetData(cs, transactions);
            });

            let total : any = {};
            datum.forEach((d: any) => {
                total["Monto propuesta"] = total["Monto propuesta"] ? total["Monto propuesta"] + d.budget : d.budget;
                total["Acuerdos"] = total["Acuerdos"] ? total["Acuerdos"] + d.agreementsTotal : d.agreementsTotal;
                total["Recuperable"] = total["Recuperable"] ? total["Recuperable"] + d.recoverable : d.recoverable;
                total["Total + Extras"] = total["Total + Extras"] ? total["Total + Extras"] + d.total : d.total;
                total["Balance"] = total["Balance"] ? total["Balance"] + d.balance : d.balance;
                total["Facturas"] = total["Facturas"] ? total["Facturas"] + d.invoicesTotal : d.invoicesTotal;
                total["Facturas Pagadas"] = total["Facturas Pagadas"] ? total["Facturas Pagadas"] + d.paidInvoices : d.paidInvoices;
                total["Facturas Pendientes"] = total["Facturas Pendientes"] ? total["Facturas Pendientes"] + d.pendingInvoices : d.pendingInvoices;
            });
            setData(datum);
            setResume(total);
        }

    }, [transactions, controlSheets]);

    console.log(resume);

    let cols = [
        {name: <div>Hojas de Control</div>, wrap: true, selector: (row: any) => row.name , sortable: true, width: "200px"},
        {name: <div>Monto Propuesta</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.budget)}` , sortable: true},
        {name: <div>Acuerdos</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.agreementsTotal)}` , sortable: true},
        {name: <div>Recuperable</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.recoverable)}` , sortable: true},
        {name: <div>Total + Extras</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.total)}` , sortable: true},
        {name: <div>Balance</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row. balance)}`, sortable: true},
        {name: <div>Facturas</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.invoicesTotal)}` , sortable: true},
        {name: <div>Facturas Pagadas</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.paidInvoices)}` , sortable: true},
        {name: <div>Facturas Pendientes</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row.pendingInvoices)}` , sortable: true},
    ];

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Dashboard Control de Hojas</h1>
            </div>
            <div className="flex gap-2 my-4">
                {Object.keys(resume).map((key: string) => {
                    return <InfoCard key={key} title={key} content={[
                        `$${formatCurrency(resume[key])}`
                        ]}
                    />
                })}

            </div>
            <DataTable
                columns={cols}
                data={data}
            />
        </Layout>
    );
}

export default ControlSheetDashboardPage;