import {useEffect, useState} from "react";

interface IProps {
    doc: any;
    project: any;
    controlSheets: any[];
    handleSubmit: Function;
}

const PaymentDocFormComponent = (props: IProps) => {

    const [data, setData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (props.doc) {
            setData({...props.doc, controlSheet: props.doc.controlSheet ? props.doc.controlSheet._id: null});
        }
    }, []);

    const handleChange = (e: any) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const checkAndSubmit = (data: any) => {
        props.handleSubmit(data);
    }

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="name">Cliente</label>
                    <input
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                        type="text" name="name" value={data.client} onChange={handleChange} readOnly={true}/>
                    <small className="text-red-500 font-bold">{errors.name}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="name">Descripción</label>
                    <input
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                        type="text" name="name" value={data.description} onChange={handleChange} readOnly={true}/>
                    <small className="text-red-500 font-bold">{errors.name}</small>
                </div>


                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700"
                           htmlFor="description">Familia</label>
                    <select
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white"
                        name="family" value={data.family} onChange={handleChange}>
                        <option value="">Seleccione una familia</option>
                        {props.project.families.sort((a:any, b:any) => a.name < b.name ? -1 : 1).map((family: any, index: number) => (
                            <option key={index} value={family.name}>{family.name}</option>
                        ))}
                    </select>
                    <small className="text-red-500 font-bold">{errors.family}</small>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700"
                           htmlFor="description">Hoja de Control</label>
                    <select
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white"
                        name="controlSheet" value={data.controlSheet} onChange={handleChange}>
                        <option value="">Seleccione una hoja de control</option>
                        {props.controlSheets.sort((a:any, b:any) => a.name < b.name ? -1 : 1).map((controlSheet: any, index: number) => (
                            <option key={index} value={controlSheet._id}>{controlSheet.name}</option>
                        ))}
                    </select>
                    <small className="text-red-500 font-bold">{errors.family}</small>
                </div>
            </div>
            <div className="flex justify-end p-5">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => checkAndSubmit(data)}>Guardar
                </button>
            </div>
        </div>

    );
}

export default PaymentDocFormComponent;