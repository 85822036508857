import axios, {AxiosError, AxiosInstance} from 'axios';
import {BASE_URL} from "./constants";

export interface IServerResponse<T> {
    data: T;
    message: string;
}

export interface IResponse<T> {
    data: T | null;
    error: string | null;
}


export default class ClientAxios {
    private static instance: ClientAxios;
    private axios: AxiosInstance;

    private constructor() {
        this.axios = new (axios.create as any)({
            baseURL: BASE_URL,
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }) as AxiosInstance;
    }

    public static getInstance(): ClientAxios {
        if (!ClientAxios.instance) {
            ClientAxios.instance = new ClientAxios();
        }
        return ClientAxios.instance;
    }


  async login(email: string, password: string) : Promise<IResponse<any>>  {
        try {
            let response = await this.axios.post('/auth/login', { email, password });
            if (response.data.error) {
                return { data: null, error: response.data.error };
            }
            return { data: response.data, error: null };
        } catch (e : any) {
            return { data: null, error: e.response.data.message };
        }
  }

  async register(name: string, email: string, password: string) : Promise<IResponse<any>>  {
        try {
            let response = await this.axios.post('/register', { name, email, password });
            if (response.data.error) {
                return { data: null, error: response.data.error };
            }
            return { data: response.data, error: null };
        } catch (e: any) {
            return { data: null, error: e.response.data.error };
        }
  }

  async getProjects() : Promise<IResponse<any>> {
    try {
      let response = await this.axios.get('/projects');
      if (response.data.error) {
        return { data: null, error: response.data.error };
      }
      return { data: response.data, error: null };
    } catch (e: any) {
      return { data: null, error: e.response.data.error };
    }
  }

    async getPlanifications(projectId: string) : Promise<IResponse<any>> {
        try {
        let response = await this.axios.get(`/planifications?project=${projectId}`);
        if (response.data.error) {
            return { data: null, error: response.data.error };
        }
        return { data: response.data.planifications, error: null };
        } catch (e: any) {
        return { data: null, error: e.response.data.error };
        }
    }

    async updatePlanification(planification: any) : Promise<IResponse<any>> {
        try {
            let response = await this.axios.put(`/planifications/${planification._id}`, planification);
            if (response.data.error) {
                return { data: null, error: response.data.error };
            }
            return { data: response.data.planification, error: null };
        } catch (e: any) {
            return { data: null, error: e.response.data.error };
        }
    }

    async getControlSheets(projectId: string) : Promise<IResponse<any>> {
        try {
        let response = await this.axios.get(`/controlSheets?project=${projectId}`);
        if (response.data.error) {
            return { data: null, error: response.data.error };
        }
        return { data: response.data.controlSheets, error: null };
        } catch (e: any) {
        return { data: null, error: e.response.data.error };
        }
    }

    async updateControlSheet(controlSheet: any) : Promise<IResponse<any>> {
        try {
        let response = await this.axios.put(`/controlSheets/${controlSheet._id}`, controlSheet);
        if (response.data.error) {
            return { data: null, error: response.data.error };
        }
        return { data: response.data.controlSheet, error: null };
        } catch (e: any) {
        return { data: null, error: e.response.data.error };
        }
    }

    async getTransactions(projectId: string) : Promise<IResponse<any>> {
        try {
        let response = await this.axios.get(`/transactions?project=${projectId}`);
        if (response.data.error) {
            return { data: null, error: response.data.error };
        }
        return { data: response.data.transactions, error: null };
        } catch (e: any) {
        return { data: null, error: e.response.data.error };
        }
    }

    async updateTransaction(transaction: any) : Promise<IResponse<any>> {
        try {
        let response = await this.axios.put(`/transactions/${transaction._id}`, transaction);
        if (response.data.error) {
            return { data: null, error: response.data.error };
        }
        return { data: response.data.transaction, error: null };
        } catch (e: any) {
        return { data: null, error: e.response.data.error };
        }
    }
}