import {useEffect, useState} from "react";
import Swal from "sweetalert2";

const ObservationFormComponent = (
    {
                                                modification,
                                                project,
                                                handleSubmit} : {
        modification?: any,
    project: any,
    handleSubmit: Function
}) => {
    const [data, setData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (modification) {
            setData(modification);
        }
    }, [modification]);

    const handleChange = (e: any) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const checkAndSubmit = (data: any) => {
        let errors : any = {};
        if (!data.name) {
            errors.name = "El nombre es requerido";
        }
        if (!data.family) {
            errors.family = "La familia es requerida";
        }
        if (!data.description) {
            errors.description = "La descripción es requerida";
        }
        if (!data.realAmount) {
            errors.realAmount = "El monto real es requerido";
        }
        if (!data.recoverAmount) {
            errors.recoverAmount = "El monto recuperado es requerido";
        }
        if (!data.observation) {
            errors.observation = "La observación es requerida";
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        handleSubmit(data);
    }

    /*
    name: { type: String, required: true },
    description: { type: String, required: true },
    realAmount: { type: Number, required: true },
    recoverAmount: { type: Number, required: true },
    observation: { type: String, required: true },
    family: { type: String, required: true }, // Related to project families by name
     */
    return (
        <div>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="name">Nombre</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="text" name="name" value={data.name} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.name}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="description">Familia</label>
                    <select className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white" name="family" value={data.family} onChange={handleChange}>
                        <option value="">Seleccione una familia</option>
                        {project.families.map((family: any, index: number) => (
                            <option key={index} value={family.name}>{family.name}</option>
                        ))}
                    </select>
                    <small className="text-red-500 font-bold">{errors.family}</small>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="description">Descripción</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="text" name="description" value={data.description} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.description}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="realAmount">Monto real</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="number" name="realAmount" value={data.realAmount} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.realAmount}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="recoverAmount">Monto recuperado</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="number" name="recoverAmount" value={data.recoverAmount} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.recoverAmount}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="observation">Observación</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="text" name="observation" value={data.observation} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.observation}</small>
                </div>
            </div>
            <div className="flex justify-end p-5">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => checkAndSubmit(data)}>Guardar</button>
            </div>
        </div>

    )
}

export default ObservationFormComponent;
