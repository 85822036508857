import {useEffect, useState} from "react";

const WorkloadForm = ({
    project,
    workload,
    handleSubmit
    } : {
    project: any,
    workload?: any,
    handleSubmit: Function
}) => {

    const [data, setData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});
    const [fields, setFields] = useState<string[]>([]);

    useEffect(() => {
        if (project) {
            let fields = project.workload.map((wk: {name: string}) => wk.name);
            setFields(fields);
        }
    }, [project]);

    useEffect(() => {
        if (workload) {
            setData(workload);
        }
    }, [workload]);

    const handleChange = (e: any) => {
        setData({...data, [e.target.name]: parseInt(e.target.value)});
    }

    const checkAndSubmit = (data: any) => {
        let errors : any = {};
        fields.forEach((field: string) => {
            if (!data[field]) {
                errors[field] = `El campo ${field} es requerido`;
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        handleSubmit(data);
    }

    return (
        <div>
            <div className="flex flex-col gap-4">
                <h2 className="text-xl font-bold my-4">{workload ? "Editar carga de trabajo" : "Crear carga de trabajo"}</h2>
                {fields.map((field: string, index: number) => (
                    <div key={index} className="flex flex-col gap-2">
                        <label htmlFor={field} className="text-sm text-start font-medium text-gray-700">{field}</label>
                        <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="number" min={0} name={field} value={data[field]} onChange={handleChange}/>
                        <small className="text-red-500 font-bold">{errors[field]}</small>
                    </div>
                ))}
                <button onClick={() => checkAndSubmit(data)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Guardar</button>
            </div>
        </div>
    );





}

export default WorkloadForm;