
export const calculateBudget = (controlSheet: any)=> {
    return controlSheet.budget
}

export const calculateAgreement = (controlSheet: any)=> {
    return controlSheet.agreements.reduce((a: number, b: any) => b.amount + a, 0)
}

export const calculateRecoverable = (controlSheet: any)=> {
    return controlSheet.observations.reduce((a: number, b: any) => b.recoverAmount + a, 0);
}

export const calculateOCs = (controlSheet: any, transactions: any[])=> {
    let OCs = transactions.filter((transaction: any) => transaction.type === 'OC' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);
    return OCs.reduce((a: number, b: any) => b.total + a, 0);
}

export const calculateNNCCs = (controlSheet: any, transactions: any[])=> {
    let NNCCs = transactions.filter((transaction: any) => transaction.type === 'NNCC' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);
    return NNCCs.reduce((a: number, b: any) => b.total + a, 0);
}

export const calculateSpends = (controlSheet: any, transactions: any[])=> {
    let invoices = transactions.filter((transaction: any) => transaction.type === 'FACTURA' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);
    return invoices.reduce((a: number, b: any) => b.total + a, 0);
}

export const calculatePaidSpends = (controlSheet: any, transactions: any[])=> {
    let invoices = transactions
        .filter((transaction: any) => transaction.type === 'FACTURA' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id)
        .filter((invoice: any) => invoice.paymentStatus.toLowerCase() === 'pagada');
    return invoices.reduce((a: number, b: any) => b.total + a, 0);
}

export const calculateControlSheetData = (controlSheet: any, transactions: any[])=> {
    let agreements = calculateAgreement(controlSheet);
    let recoverable = calculateRecoverable(controlSheet);
    let total = calculateBudget(controlSheet) + recoverable;

    let invoicesTotal = calculateSpends(controlSheet, transactions);
    let paidInvoices = calculatePaidSpends(controlSheet, transactions);

    let invoices = transactions.filter((transaction: any) => transaction.type === 'FACTURA' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);
    let OCs = transactions.filter((transaction: any) => transaction.type === 'OC' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);
    let NNCCs = transactions.filter((transaction: any) => transaction.type === 'NNCC' && transaction.controlSheet && transaction.controlSheet._id === controlSheet._id);


    return {
        controlSheet,
        name: controlSheet.name,
        budget: calculateBudget(controlSheet),
        observations: controlSheet.observations,
        recoverable,
        agreements: controlSheet.agreements,
        agreementsTotal: agreements,
        total,
        balance: total - agreements,
        invoicesTotal,
        paidInvoices,
        pendingInvoices: invoicesTotal - paidInvoices,
        NNCCsTotal: calculateNNCCs(controlSheet, transactions),
        OCsTotal: calculateOCs(controlSheet, transactions),
        invoices,
        OCs,
        NNCCs
    }
}

