import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../Context/MainContext";
import Layout from "../../Layout/Layout.component";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../../utils/number.utils";
import TextFilterComponent from "../../Components/Filtering/TextFilter.component";
import SelectFilter from "../../Components/Filtering/SelectFilter.component";
import PaymentDocFormComponent from "../../Components/Forms/PaymentDocForm.component";
import ClientAxios from "../../Client.axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal)

const OCsPage = () => {

    const { transactions, setTransactions, controlSheets, selectedProject } = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);
    const [family, setFamily] = useState<any[]>([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filterFamily, setFilterFamily] = useState('');


    const filteredItems = data
        .filter(item => item.client && item.client.toLowerCase().includes(filterText.toLowerCase()))
        .filter(item => {
            if (filterFamily === "Sin Familia") {
                return !item.family;
            } else if (filterFamily === "") {
                return true;
            } else {
                return item.family && item.family.toLowerCase().includes(filterFamily.toLowerCase());
            }
        });

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        const handleClearFamily = () => {
            if (filterFamily) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterFamily('');
            }
        }
        return <>
            <SelectFilter
                selectOptions={["Sin Familia", ...selectedProject.families.filter((f:any) => f.selectable).map((f:any) => f.name)]}
                selected={filterFamily}
                onFilter={value => setFilterFamily(value)}
                onClear={handleClearFamily}
            />

            <TextFilterComponent onFilter={value => setFilterText(value)} onClear={handleClear} filterText={filterText} />
        </>;
    }, [filterText, filterFamily ,resetPaginationToggle]);

    useEffect(() => {
        if (transactions.length > 0) {
            let tmp = transactions.filter((transaction: any) => transaction.type === 'OC');
            setData(tmp);
        }
    }, [transactions]);

    let columns = [
        { wrap: true, maxWidth: "10%", name: "Fecha", selector: (row: any) => new Date(row.date).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() },
        { wrap: true, maxWidth: "10%", name: "Familia", selector: (row: any) => row.family, sortable: true },
        { wrap: true, maxWidth: "15%", name: "Hoja de control", selector: (row: any) => row.controlSheet ? row.controlSheet.name : "", sortable: true },
        { wrap: true, maxWidth: "10%", name: "O/C", selector: (row: any) => row.rawValue.cabecera.documento.numOc },
        { wrap: true, maxWidth: "15%", name: "Proveedor", selector: (row: any) => row.client, sortable: true },
        { wrap: true, maxWidth: "20%", name: "Descripción", selector: (row: any) => row.description },
        { wrap: true, maxWidth: "10%", name: "Monto", selector: (row: any) => `$${formatCurrency(row.total)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total) },
        { wrap: true, maxWidth: "10%", name: "Estado", selector: (row: any) => row.status },
        {
            wrap: true, maxWidth: "10%", name: "Acciones", selector: (row: any) => {
                return (
                    <div className="flex gap-2">
                        <button className="bg-blue-500 text-white px-4 py-2 rounded-md" onClick={() => showInvoiceEditModal(row)}>Editar</button>
                    </div>
                );
            }
        }
        ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const showInvoiceEditModal = (row: any) => {
        MySwal.fire({
            title: "Editar Orden de Compra",
            html: (<PaymentDocFormComponent
                project={selectedProject}
                controlSheets={controlSheets}
                doc={row}
                handleSubmit={(data: any) => {
                    let payload = {...row, ...data};
                    console.log("Payload", payload);

                    // Update the invoice
                    let client = ClientAxios.getInstance();
                    client.updateTransaction(payload)
                        .then((response: any) => {
                            if (response.error) {
                                MySwal.fire("Error", response.error, "error");
                            } else {
                                let newTransaction = response.data;
                                let transactionsTmp = transactions.map((tr: any) => {
                                    if (tr._id === newTransaction._id) {
                                        return newTransaction;
                                    }
                                    return tr;
                                });
                                setTransactions(transactionsTmp);
                                MySwal.fire("Guardado", "La orden de compra ha sido actualizada", "success");
                            }
                        });
                    MySwal.close();
                }}
            />),
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Cancelar",
        }).then((result: any) => {
            if (result.isConfirmed) {
                MySwal.fire("Guardado", "La orden de compra ha sido actualizada", "success");
            }
        });
    }


    return (<Layout>
        <div className="container">
            <h1 className="text-2xl font-bold mb-4">Ordenes de Compra</h1>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
            />
        </div>
    </Layout>

    );
}

export default OCsPage;