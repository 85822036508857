import Layout from "../../Layout/Layout.component";
import {ResponsiveLine} from "@nivo/line";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../Context/MainContext";
import {datetoDateFormat} from "../../utils/date.utils";
import {formatCurrency} from "../../utils/number.utils";
import InfoCard from "../../Components/InfoCard.component";
import {calculateEarnValuesKPIs} from "../../utils/project.utils";
import {getProjectResume} from "../../utils/planification.utils";

const GeneralReportPage = () => {

    const { transactions, planifications, selectedProject } = useContext(AppContext);
    const [data, setData] = useState<any>(null);
    const [KPIs, setKPIs] = useState<any>(null);
    const [showAllProject, setShowAllProject] = useState(false);
    const [currentWeek, setCurrentWeek] = useState(0);

    useEffect(() => {
        if (planifications.length > 0) {
            let chartDatum: any[]  = [];
            // today plus 30 days
            let today = new Date();
            today.setDate(today.getDate() + 30);

            let earnValueData: any = {"id": "Valor Ganado", "data": [], color: "green"};
            let invoiceData : any = {"id": "Facturas", "data": [], color: "hsl(0, 70%, 50%)"};
            let EEPPData: any = {"id": "EEPP", "data": [], color: "#000"};
            let planifiedEarnValueData: any = {"id": "Valor Planificado", "data": [], color: "blue"};

            let sortedPlanifications = planifications
                .sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
                .filter((planification: any) => showAllProject || new Date(planification.startDate) <= today);

            let currentIndex = 0;

            sortedPlanifications.forEach((planification: any, index: number) => {
                let startDate = new Date(planification.startDate);
                let endDate = new Date(planification.endDate);
                if (new Date() >= startDate && new Date() <= endDate) {
                    currentIndex = index;
                    setCurrentWeek(index+1);
                }
            });

            let resume = getProjectResume(sortedPlanifications, transactions);

            resume.forEach((planification: any, index: number) => {
                earnValueData.data.push({x: new Date(planification.startDate), y: planification.accEarnValue, name: `Semana ${index + 1}`});
                invoiceData.data.push({x: new Date(planification.startDate), y: planification.accCostValue, name: `Semana ${index + 1}`});
                EEPPData.data.push({x: new Date(planification.startDate), y: planification.accEEPP, name: `Semana ${index + 1}`});
                planifiedEarnValueData.data.push({x: new Date(planification.startDate), y: planification.accPlanifiedEarnValue, name: `Semana ${index + 1}`});
            });

            setData([
                earnValueData,
                invoiceData,
                EEPPData,
                planifiedEarnValueData
            ]);
        }
    }, [planifications, transactions, showAllProject]);

    useEffect(() => {
        if (planifications.length > 0) {
            let KPIS: any = calculateEarnValuesKPIs(selectedProject, planifications.slice(0, currentWeek), transactions);
            setKPIs(KPIS);
        }
    }, [currentWeek]);

    return (
        <Layout>
            <div className="container bg-white p-5 mt-10">
                <h1 className="text-2xl font-bold mb-4">Dashboard histórico (Semana {currentWeek})</h1>
                <div className="flex justify-between">
                    <div>
                        <div>
                            <label htmlFor="week">Semana</label>
                        </div>
                        <select
                            name="week"
                            id="week"
                            value={currentWeek}
                            onChange={(e) => setCurrentWeek(parseInt(e.target.value))}
                        >
                            {planifications.map((planification: any, index: number) => (
                                <option key={index} value={index+1}>Semana {index+1}</option>
                            ))}
                        </select>
                    </div>
                    <button
                        onClick={() => setShowAllProject(!showAllProject)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        {showAllProject ? "Ver solo fechas cercanas" : "Ver proyecto completo"}
                    </button>
                </div>

                <div>
                    <h2 className="text-xl font-bold my-4">Resumen Valor Ganado</h2>
                    <div className="flex gap-2">
                        <InfoCard
                            title="Porcentaje Avance"
                            content={[
                                `Planificado: ${KPIs ? (KPIs.planifiedEarnValuePercentage).toFixed(2) : 0}%`,
                                `Real: ${KPIs ? (KPIs.accEarnValuePercentage).toFixed(2) : 0}%`
                            ]}
                        />
                        <InfoCard
                            title="Valor Ganado"
                            content={[
                                `Planificado: $${formatCurrency(KPIs ? KPIs.accPlanifiedEarnValue : 0)}`,
                                `Real: $${formatCurrency(KPIs ? KPIs.accEarnValue : 0)}`
                            ]}
                        />
                        <InfoCard
                            title="Costos"
                            content={[
                                `Mano de obra: $${formatCurrency(KPIs ? KPIs.planification.accWorkloads : 0)}`,
                                `Facturas: $${formatCurrency(KPIs ? KPIs.planification.invoiceTotal : 0)}`,
                            ]}
                        />
                        {KPIs && <InfoCard
                            title="Indicadores"
                            content={[
                                `CPI: ${KPIs.CPI.toFixed(2)}`,
                                `SPI: ${KPIs.SPI.toFixed(2)}`,
                            ]}
                        />}
                        {KPIs && <InfoCard
                            title="Duración"
                            content={[
                                `Duración: ${KPIs.duration} días`,
                                KPIs.currentDelay > 0 ?
                                    `Atraso: ${KPIs.currentDelay.toFixed(0)} días` :
                                    `Adelanto: ${Math.abs(KPIs.currentDelay).toFixed(0)} días`
                                ,
                                `Proyección a termino: ${KPIs.expectedDuration.toFixed(0)} días`,
                            ]}
                        />}
                    </div>

                </div>
                <div className="info-cards">
                    {data &&
                        <div style={{height: "700px", width: "100%"}}>

                            <ResponsiveLine
                                data={data}
                                colors={(d: any) => d.color}
                                markers={[
                                    {
                                        axis: 'x',
                                        value: new Date(),
                                        lineStyle: { stroke: '#afafafaa', strokeWidth: 3 },
                                        legend: 'Hoy'
                                    }
                                ]}
                                margin={{ top: 50, right: 110, bottom: 50, left: 150 }}
                                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'day'}}
                                xFormat="time:%Y-%m"
                                yScale={{ type: 'linear', min: 0, max: 'auto', reverse: false }}
                                yFormat={function(e: any) { return `$${formatCurrency(e)}`; }}
                                curve="monotoneX"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    format: '%b %Y',
                                    tickValues: showAllProject ? 'every 2 month' : 'every 1 month',
                                    legend: 'Fecha',
                                    legendOffset: 36,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    legend: 'Monto',
                                    legendOffset: -130,
                                    legendPosition: 'middle',
                                    format: function(e: any) { return `$${formatCurrency(e/1000000)} (MM)`; }
                                }}
                                enableGridX={false}
                                enableGridY={true}
                                pointSize={5}
                                // pointBorderColor={{ from: 'serieColor' }}
                                pointLabelYOffset={-12}
                                // useMesh={true}
                                enableSlices={'x'}
                                sliceTooltip={(data) => {
                                    let slice = data.slice;
                                    return (
                                        <div
                                            style={{
                                                background: 'white',
                                                padding: '9px 12px',
                                                border: '1px solid #ccc',
                                            }}
                                        >
                                            <div>
                                                <span>
                                                    <strong>{(slice.points[0].data as any).name}</strong> ({(slice.points[0].data.x as Date).toLocaleDateString()})
                                                </span>


                                            </div>
                                            {slice.points.map(point => (
                                                <div
                                                    key={point.id}
                                                    className="flex items-center gap-2"
                                                    style={{
                                                        padding: '3px 0',
                                                    }}
                                                >
                                                    <div className="rounded h-2 w-2" style={{background: point.serieColor,}}></div>
                                                    <span>
                                                        <strong>{point.serieId}</strong>: ${formatCurrency(point.data.y as number)}
                                                    </span>

                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 80,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                        }
                </div>
            </div>
        </Layout>
    );
}

export default GeneralReportPage;