import {useEffect, useState} from "react";
import Swal from "sweetalert2";

const AgreementForm = ({
    agreement,
    project,
    handleSubmit} : {
    agreement?: any,
    project: any,
    handleSubmit: Function
}) => {

    const [data, setData] = useState<any>({});
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {
        if (agreement) {
            setData(agreement);
        }
    }, [agreement]);

    const handleChange = (e: any) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const checkAndSubmit = (data: any) => {
        let errors : any = {};
        if (!data.name) {
            errors.name = "El nombre es requerido";
        }
        if (!data.family) {
            errors.family = "La familia es requerida";
        }
        if (!data.description) {
            errors.description = "La descripción es requerida";
        }
        if (!data.amount) {
            errors.amount = "El monto es requerido";
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        handleSubmit(data);
    }

    return (
        <div>
            <div className="flex flex-col gap-4">
                <h2 className="text-xl font-bold my-4">{agreement ? "Editar observación" : "Crear observación"}</h2>
                <div className="flex flex-col gap-2">
                    <label htmlFor="name" className="text-sm text-start font-medium text-gray-700">Nombre</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="text" name="name" value={data.name} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.name}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="description">Familia</label>
                    <select
                        className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm bg-white"
                        name="family" value={data.family} onChange={handleChange}>
                        <option value="">Seleccione una familia</option>
                        {project.families.map((family: any, index: number) => (
                            <option key={index} value={family.name}>{family.name}</option>
                        ))}
                    </select>
                    <small className="text-red-500 font-bold">{errors.family}</small>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="description">Descripción</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="text" name="description" value={data.description} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.description}</small>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-start font-medium text-gray-700" htmlFor="amount">Proyectado</label>
                    <input className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm" type="number" name="amount" value={data.amount} onChange={handleChange}/>
                    <small className="text-red-500 font-bold">{errors.amount}</small>
                </div>
            </div>
            <div className="flex justify-end p-5">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => checkAndSubmit(data)}>Guardar</button>
            </div>
        </div>

    )
}

export default AgreementForm;