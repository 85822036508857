import Layout from "../../Layout/Layout.component";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../Context/MainContext";
import InfoCard from "../../Components/InfoCard.component";
import {formatCurrency} from "../../utils/number.utils";
import DataTable from "react-data-table-component";
import ClientAxios from "../../Client.axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AgreementForm from "../../Components/Forms/AgreementForm.component";
import ObservationForm from "../../Components/Forms/ObservationForm.component";
import {calculateControlSheetData} from "../../utils/controlSheet.utils";
const MySwal = withReactContent(Swal)

const ControlSheetPage = () => {

    const [selectedControlSheet, setSelectedControlSheet] = useState<any>(null);
    const [selectedFamily, setSelectedFamily] = useState<string|null>(null);
    const [data, setData] = useState<any>(null);
    const {selectedProject, controlSheets, transactions, setControlSheets} = useContext(AppContext);

    const showCreateAgreementModal = () => {
        MySwal.fire({
            html: <AgreementForm
                project={selectedProject}
                handleSubmit={async (data: any) => {
                    MySwal.showLoading()
                    let tmp = selectedControlSheet;
                    tmp.agreements.push(data);
                    await updateControlSheet(tmp);
                    MySwal.close();
                }} />,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
        })

    }

    const showEditAgreementModal = (agreement: any) => {
        MySwal.fire({
            html: <AgreementForm
                handleSubmit={async (data: any) => {
                    MySwal.showLoading()
                    let tmp = selectedControlSheet;
                    tmp.agreements = tmp.agreements.map((agr: any) => agr._id === data._id ? data : agr);
                    await updateControlSheet(tmp);
                    MySwal.close();
                }}
                agreement={agreement}
                project={selectedProject}
            />,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
        })
    }

    const showDeleteAgreementModal = (agreement: any) => {
        MySwal.fire({
            title: 'Eliminar observación',
            text: '¿Está seguro que desea eliminar esta observación?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: 'red',
            showCloseButton: true,
            preConfirm: async () => {
                let tmp = selectedControlSheet;
                tmp.agreements = tmp.agreements.filter((agr: any) => agr._id !== agreement._id);

                let client = ClientAxios.getInstance();
                let response = await client.updateControlSheet(tmp);

                if (response.error) {
                    console.log(response.error);
                } else {
                    setSelectedControlSheet(response.data);
                    setControlSheets(controlSheets.map((controlSheet: any) => controlSheet._id === response.data._id ? response.data : controlSheet));
                }
            }
        })

    }

    const showCreateModificationModal = () => {
        MySwal.fire({
            html: <ObservationForm
                project={selectedProject}
                handleSubmit={async (data: any) => {
                    MySwal.showLoading()
                    let tmp = selectedControlSheet;
                    tmp.observations.push(data);
                    await updateControlSheet(tmp);
                    MySwal.close();
                }} />,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
        })
    }

    const showEditModificationModal = (observation: any) => {
        MySwal.fire({
            html: <ObservationForm
                handleSubmit={async (data: any) => {
                    MySwal.showLoading()
                    let tmp = selectedControlSheet;
                    tmp.observations = tmp.observations.map((obs: any) => obs._id === data._id ? data : obs);
                    await updateControlSheet(tmp);
                    MySwal.close();
                }}
                modification={observation}
                project={selectedProject}
            />,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
        })
    }

    const showDeleteModificationModal = (observation: any) => {
        MySwal.fire({
            title: 'Eliminar observación',
            text: '¿Está seguro que desea eliminar esta observación?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: 'red',
            showCloseButton: true,
            preConfirm: async () => {
                let tmp = selectedControlSheet;
                tmp.observations = tmp.observations.filter((obs: any) => obs._id !== observation._id);

                let client = ClientAxios.getInstance();
                let response = await client.updateControlSheet(tmp);

                if (response.error) {
                    console.log(response.error);
                } else {
                    setSelectedControlSheet(response.data);
                    setControlSheets(controlSheets.map((controlSheet: any) => controlSheet._id === response.data._id ? response.data : controlSheet));
                }
            }
        })
    }


    const updateControlSheet = async (newControlSheet: any) => {
        let client = ClientAxios.getInstance();
        let response = await client.updateControlSheet(newControlSheet);

        if (response.error) {
            console.log(response.error);
        } else {
            setSelectedControlSheet(response.data);
            setControlSheets(controlSheets.map((controlSheet: any) => controlSheet._id === response.data._id ? response.data : controlSheet));
        }
    }

    useEffect(() => {
        if (selectedControlSheet && transactions.length > 0) {

            let filteredTransactions = [...transactions];
            let controlSheet = {...selectedControlSheet};

            if (selectedFamily) {
                filteredTransactions = transactions.filter((transaction: any) => transaction.family === selectedFamily);
                controlSheet.agreements = controlSheet.agreements.filter((agreement: any) => agreement.family === selectedFamily);
                controlSheet.observations = controlSheet.observations.filter((observation: any) => observation.family === selectedFamily);
            }

            let datum = calculateControlSheetData(controlSheet, filteredTransactions);

            setData(datum);
        }
    }, [selectedControlSheet, transactions, selectedFamily]);

    return (
        <Layout>
        <div className="container">
            <h1 className="text-2xl font-bold mb-4">Hojas de control</h1>
            {/*select a control sheet*/}
            <select className="border border-gray-300 rounded-md p-2" onChange={(e) => {
                setSelectedFamily(null);
                setSelectedControlSheet(controlSheets
                    .find((controlSheet: any) => controlSheet._id === e.target.value));
            }}>
                <option>Seleccione una hoja de control</option>
                {controlSheets && controlSheets.length > 0 && controlSheets
                    .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((controlSheet: any) => {
                        return (
                            <option
                                key = {controlSheet._id}
                                value = {controlSheet._id} > {`${controlSheet.name} ${controlSheet.codes.length > 0 ? `(${(controlSheet.codes.map((code: any) => code.family).join(", "))})` : ""}`}
                            </option>
                        )
                    })}
            </select>
            {/*display selected control sheet*/}
            {selectedControlSheet && data && <div>
                <h2 className="text-xl font-bold mt-4">Detalle de la hoja de control: {selectedControlSheet.name}</h2>
                {selectedControlSheet.codes.length > 1 &&
                    <div  className="my-2">
                        <label className="text font-bold mt-4">Filtro por familia/código</label>
                        <select className="border border-gray-300 rounded-md p-2" onChange={(e) => {
                            setSelectedFamily(e.target.value);
                        }}>
                            <option value="">Seleccione una familia/código</option>
                            {selectedControlSheet.codes.map((code: any) => {
                                return (
                                    <option
                                        key = {code.family}
                                        value = {code.family} > {code.family}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    }
                <div className="flex gap-2">
                    <InfoCard
                        title="Monto Propuesto"
                        content={[`$${formatCurrency(selectedControlSheet.budget)}`]}
                    />
                    <InfoCard
                        title={"Monto Contrato"}
                        content={[`$${formatCurrency(data.agreementsTotal)}`]}
                    />

                    <InfoCard
                        title={"Recuperable"}
                        content={[`$${formatCurrency(data.recoverable)}`]}
                    />

                    <InfoCard
                        title={"Total con extras"}
                        content={[`$${formatCurrency(data.total)}`]}
                    />

                    <InfoCard title={"Balance"} content={[
                        `$${formatCurrency(data.balance)}`
                    ]}
                    />

                    <InfoCard
                        title={"Factuaras emitidas"}
                        content={[`$${formatCurrency(data.invoicesTotal)}`]}
                    />


                    <InfoCard
                        title={"Facturas pagadas"}
                        content={[`$${formatCurrency(data.paidInvoices)}`]}
                    />


                    <InfoCard
                        title={"Facturas pendientes"}
                        content={[`$${formatCurrency(data.pendingInvoices)}`]}
                    />


                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Observaciones al Contrato</h2>
                    <div className="flex gap-2 justify-end mb-2">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={showCreateAgreementModal}>Agregar Observación</button>
                    </div>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {name: "Familia", selector: (row: any) => row.family},
                                {name: "Glosa", selector: (row: any) => row.name},
                                {name: "Descripción", selector: (row: any) => row.description},
                                {name: "Monto", selector: (row: any) => `$${formatCurrency(row.amount)}`},
                                {name: "", selector: (row: any) => (<div className="flex justify-center gap-2">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => showEditAgreementModal(row)}>Editar</button>
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => showDeleteAgreementModal(row)}>Eliminar</button>
                                </div>)}
                            ]}
                            data={data.agreements}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay observaciones</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Aumentos/Disminuciones al Contrato</h2>
                    <div className="flex gap-2 justify-end mb-2">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={showCreateModificationModal}>Agregar Aumento/Disminución</button>
                    </div>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {name: "Familia", selector: (row: any) => row.family},
                                {name: "Glosa", selector: (row: any) => row.name},
                                {name: "Descripción", selector: (row: any) => row.description},
                                {name: "Monto real", selector: (row: any) => `$${formatCurrency(row.realAmount)}`},
                                {
                                    name: "Monto recuperable",
                                    selector: (row: any) => `$${formatCurrency(row.recoverAmount)}`
                                },
                                {name: "", selector: (row: any) => (<div className="flex justify-center gap-2">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => showEditModificationModal(row)}>Editar</button>
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={() => showDeleteModificationModal(row)}>Eliminar</button>
                                </div>)}
                            ]}
                            data={data.observations}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay aumento/disminuciones</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Facturas: ${formatCurrency(data.invoicesTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {
                                    name: "Fecha",
                                    wrap: true,
                                    maxWidth: "10%",
                                    selector: (row: any) => new Date(row.date).toLocaleDateString(),
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()
                                },
                                {
                                    name: "Familia",
                                    wrap: true,
                                    maxWidth: "10%",
                                    selector: (row: any) => row.family,
                                    sortable: true
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Factura",
                                    selector: (row: any) => row.rawValue.cabecera.documento.numDocumento
                                },
                                {
                                    wrap: true,
                                    maxWidth: "20%",
                                    name: "Proveedor",
                                    selector: (row: any) => row.client,
                                    sortable: true
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Descripción",
                                    selector: (row: any) => row.description
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Monto",
                                    selector: (row: any) => `$${formatCurrency(row.total)}`,
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total)
                                },
                                {wrap: true, maxWidth: "15%", name: "Estado", selector: (row: any) => row.status},
                                {
                                    wrap: true,
                                    maxWidth: "15%",
                                    name: "Estado de pago",
                                    selector: (row: any) => row.paymentStatus
                                },
                            ]}
                            data={data.invoices}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay facturas</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Notas de crédito: ${formatCurrency(data.NNCCsTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {
                                    name: "Fecha",
                                    selector: (row: any) => new Date(row.date).toLocaleDateString(),
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()
                                },
                                {name: "Familia", selector: (row: any) => row.family || "-", sortable: true},
                                {name: "Factura", selector: (row: any) => row.rawValue.factAsociada || "-"},
                                {name: "Proveedor", selector: (row: any) => row.client, sortable: true},
                                // { name: "Descripción", selector: (row: any) => row.description },
                                {
                                    name: "Monto",
                                    selector: (row: any) => `$${formatCurrency(row.total)}`,
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total)
                                },
                                {name: "Estado", selector: (row: any) => row.status},
                                // { name: "Estado de pago", selector: (row: any) => row.paymentStatus },
                            ]}
                            data={data.NNCCs}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay notas de crédito</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Orden de compra: ${formatCurrency(data.OCsTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                { wrap: true, maxWidth: "10%", name: "Fecha", selector: (row: any) => new Date(row.date).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() },
                                { wrap: true, maxWidth: "10%", name: "Familia", selector: (row: any) => row.family, sortable: true },
                                { wrap: true, maxWidth: "10%", name: "O/C", selector: (row: any) => row.rawValue.cabecera.documento.numOc },
                                { wrap: true, maxWidth: "15%", name: "Proveedor", selector: (row: any) => row.client, sortable: true },
                                { wrap: true, maxWidth: "20%", name: "Descripción", selector: (row: any) => row.description },
                                { wrap: true, maxWidth: "10%", name: "Monto", selector: (row: any) => `$${formatCurrency(row.total)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total) },
                                { wrap: true, maxWidth: "10%", name: "Estado", selector: (row: any) => row.status },
                            ]}
                            data={data.OCs}
                            contextMessage={{
                                singular: 'orden de compra',
                                plural: 'ordenes de compra',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay notas de crédito</div>}
                        />
                    </div>
                </div>
            </div>
            }
        </div>
        </Layout>
    );
}

export default ControlSheetPage;