import Layout from "../Layout/Layout.component";
import {useContext, useEffect, useState} from "react";
import {PAGE_STATUS} from "../interfaces";
import Loading from "../Components/Loading.component";
import ClientAxios from "../Client.axios";
import {AppContext} from "../Context/MainContext";
import InfoCard from "../Components/InfoCard.component";
import {formatCurrency} from "../utils/number.utils";
import {getSpendsByFamilies, getSpendsByWorkload} from "../utils/planification.utils";

const DashboardPage = () => {

    const [status, setStatus] = useState(PAGE_STATUS.LOADING);
    const [data, setData] = useState<any>({});
    const {projects, planifications, selectedProject, transactions} = useContext(AppContext);

    useEffect(() => {
        if (projects.length === 0) {
            setStatus(PAGE_STATUS.NO_PROJECTS);
        } else if (!selectedProject) {
            setStatus(PAGE_STATUS.NO_SELECTED_PROJECT);
        } else {
            setStatus(PAGE_STATUS.SUCCESS);
            let spends = getSpendsByFamilies(transactions, planifications, selectedProject);
            let tmp : number[] = Object.values(spends)
            setData({
                spends,
                workloadSpends: getSpendsByWorkload(planifications, selectedProject),
                totalSpends: tmp.reduce((acc: number, spend: number) => acc + spend, 0),
                budget: selectedProject.budgets.find((budget: any) => budget.isMain).amount
            });
        }
    }, [projects, selectedProject, transactions]);

    return (<Layout>
        <div className="container">
            <Loading status={status} />
            { status === PAGE_STATUS.NO_PROJECTS &&
                <div>
                    <h1 className="text-2xl font-bold mb-4">Error</h1>
                    <p>Usted no tiene proyectos asigandos, por favor contactese con Syncroniza si considera que esto no
                        es correcto</p>
                </div>
            }
            {
                status === PAGE_STATUS.NO_SELECTED_PROJECT &&
                <div>
                    <h1 className="text-2xl font-bold mb-4">Error</h1>
                    <p>Usted no tiene un proyecto seleccionado, por favor seleccione uno</p>
                </div>
            }
            {
                status === PAGE_STATUS.SUCCESS &&
                <div>
                    <h1 className="text-2xl font-bold mb-4">Dashboard: {selectedProject.name}</h1>
                    <div className="info-cards">
                        <div className="info-card flex gap-2">
                            <InfoCard
                                title="Duración del proyecto"
                                content={[
                                    `Fecha de inicio: ${new Date(selectedProject.startDate).toLocaleDateString()}`,
                                    `Fecha de fin: ${new Date(selectedProject.endDate).toLocaleDateString()}`,
                                    `Duración: ${planifications.length} semanas`,
                                    `Semana actual: ${planifications.findIndex((planification: any) => {
                                        return new Date() >= new Date(planification.startDate) && new Date() <= new Date(planification.endDate);
                                    }) + 1}/${planifications.length}`
                                ]}
                            />
                            <InfoCard
                                title="Progreso"
                                content={[
                                    `Presupuesto: $ ${formatCurrency(data.budget)}`,
                                    `Gastado: $ ${formatCurrency(data.totalSpends)}`,
                                    `Restante: $ ${formatCurrency(data.budget - data.totalSpends)}`,
                                    `Porcentaje gastado: ${((data.totalSpends / data.budget) * 100).toFixed(1)}%`
                                ]}
                            />
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold my-4">Presupuesto</h2>
                            <div className="info-card flex gap-2">
                                {selectedProject.budgets.sort((a: any, b: any) => a.isMain).map((budget: any, index: number) => (
                                    <InfoCard
                                        key={index}
                                        title={budget.name}
                                        content={[
                                            `Monto: $ ${formatCurrency(budget.amount)}`,
                                        ]}
                                    />
                                ))}

                            </div>
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold my-4">Familias</h2>
                            <div className="info-card flex gap-2">
                                {Object.keys(data.spends).map((family: string, index: number) => {
                                    let content = [];
                                    let tmp = selectedProject.families.find((f: any) => f.name === family)
                                    content.push(`Gastado: $ ${formatCurrency(data.spends[family])}`);
                                    if ( tmp ) {
                                        content = [`Presupuesto: $ ${formatCurrency(tmp.budget)}`, ...content];
                                        content.push(`Restante: $ ${formatCurrency(tmp.budget - data.spends[family])}`);
                                        content.push(`Porcentaje gastado: ${((data.spends[family] / tmp.budget) * 100).toFixed(1)}%`);
                                    }
                                    return (<InfoCard
                                        key={index}
                                        title={family}
                                        content={content}
                                    />
                                )})}
                            </div>
                        </div>

                        <div>
                            <h2 className="text-2xl font-bold my-4">Mano de Obra</h2>
                            <div className="info-card flex gap-2">
                                {selectedProject.workload.map((workload: any, index: number) => (
                                    <InfoCard
                                        key={index}
                                        title={workload.name}
                                        content={[
                                            `Familia: ${workload.family}`,
                                            `Presupuesto: $ ${formatCurrency(workload.budget)}`,
                                            `Gastado: $ ${formatCurrency(data.workloadSpends[workload.name])}`,
                                            `Restante: $ ${formatCurrency(workload.budget - data.workloadSpends[workload.name])}`,
                                            `Porcentaje gastado: ${(((data.workloadSpends[workload.name] || 0) / workload.budget) * 100).toFixed(1)}%`
                                        ]}
                                    />
                                ))}
                            </div>

                        </div>

                    </div>
                </div>
            }
        </div>
        </Layout>
    );
}

export default DashboardPage;