import Layout from "../../Layout/Layout.component";
import {AppContext} from "../../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../../utils/number.utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import WorkloadForm from "../../Components/Forms/WorkloadForm.component";
import ClientAxios from "../../Client.axios";

const MySwal = withReactContent(Swal)

const WorkloadReportPage = () => {

    const { selectedProject, planifications, setPlanifications } = useContext(AppContext);
    const [data, setData] = useState<any>({});
    const [columns, setColumns] = useState<any[]>([]);

    // Function to get the first planification of a date
    // The date is in the format "MM-DD"
    const getFirstPlanificationOfDate = (date: string) => {
        let parts = date.split("-");
        let month = parseInt(parts[0]) - 1;
        let year = parseInt(parts[1]);
        return planifications
            .sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
            .find((pl: any) => {
                let plDate = new Date(pl.startDate);
                return plDate.getMonth() === month && plDate.getFullYear() === year;
            });
    }

    useEffect(()=> {
        if (planifications.length > 0) {

            let width = 60 / (selectedProject.workload.length * 2);
            let colsTmp = selectedProject.workload.map((wk: { name: string, budget: number }, index: number) => {
                return [
                    { name: <div>{wk.name}</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row[wk.name].budget)}`, width: `${width}%` },
                    { name: <div>{`${wk.name} acumulado`}</div>, wrap: true , selector: (row: any) => `$${formatCurrency(row[wk.name].accBudget)}`, width: `${width}%` },
                ]
            }).flat();

            // Create a monthly array of objects where start at the project start date and ends at the project end date
            // Each object will have the following structure:
            // { date: string, [name]: { budget: number, accBudget: number } }
            // Where name is the name of the workload and budget is the budget for that month, it could be multiple budgets naem
            // accBudget is the accumulated budget for that month
            // Planifications are sorted by date and are weekly, so we can iterate over them and add the budget to the corresponding month

            let startDate = new Date(selectedProject.startDate);
            let endDate = new Date(selectedProject.endDate);

            let months = [];
            let month = startDate.getMonth();
            let year = startDate.getFullYear();

            while (year < endDate.getFullYear() || (year === endDate.getFullYear() && month <= endDate.getMonth())) {
                months.push({ month: month, year: year });
                month++;
                if (month > 11) {
                    month = 0;
                    year++;
                }
            }

            let monthlyData = months.map((m: { month: number, year: number }) => {
                let obj: any = { date: `${m.month + 1}-${m.year}` };
                selectedProject.workload.forEach((wk: { name: string, budget: number }) => {
                    obj[wk.name] = { budget: 0, accBudget: 0, planifications: [] };
                });
                return obj;
            });

            planifications.forEach((pl: any) => {
                let date = new Date(pl.startDate);
                let month = date.getMonth();
                let year = date.getFullYear();
                let monthData = monthlyData.findIndex((m: any) => m.date === `${month + 1}-${year}`);
                pl.workloadValues.forEach((wl: any) => {
                    monthlyData[monthData][wl.name].budget += parseInt(wl.value);
                    monthlyData[monthData][wl.name].planifications.push(pl);
                });
            });

            let res = []
            let accBudget: any = {};
            monthlyData.forEach((m: any, index: number) => {
                selectedProject.workload.forEach((wk: { name: string }) => {
                    accBudget[wk.name] = accBudget[wk.name] ? accBudget[wk.name] + m[wk.name].budget : m[wk.name].budget;
                    m[wk.name].accBudget = accBudget[wk.name];
                });
                res.push(m);
            });

            setData(monthlyData);
            setColumns(colsTmp);
        }
    }, [planifications])

    const showWorkloadEditModal = (row: any) => {
        let planification = getFirstPlanificationOfDate(row.date);
        MySwal.fire({
            title: "Editar Mano de Obra",
            html: (<WorkloadForm
                project={selectedProject}
                workload={planification.workloadValues.length ? planification.workloadValues.reduce((acc: any, wl: any) => {
                    acc[wl.name] = wl.value
                    return acc
                }, {}) : null}
                handleSubmit={(data: any) => {
                    let workloadValues = Object.keys(data).map((key: string) => {
                        return { name: key, value: data[key] }
                    });
                    planification.workloadValues = workloadValues;

                    // Update the planification
                    let client = ClientAxios.getInstance();
                    client.updatePlanification(planification)
                        .then((response: any) => {
                            if (response.error) {
                                MySwal.fire("Error", response.error, "error");
                            } else {
                                let planificationsTmp = planifications.map((pl: any) => {
                                    if (pl._id === planification._id) {
                                        return planification;
                                    }
                                    return pl;
                                });
                                setPlanifications(planificationsTmp);
                                MySwal.fire("Guardado", "La carga de trabajo ha sido actualizada", "success");
                            }
                        });
                    MySwal.close();
                }}
            />),
            showCancelButton: true,
            confirmButtonText: "Guardar",
            cancelButtonText: "Cancelar",
        }).then((result: any) => {
            if (result.isConfirmed) {
                MySwal.fire("Guardado", "La carga de trabajo ha sido actualizada", "success");
            }
        });
    }

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Reporte Mano de Obra</h1>
                {columns.length > 0 && <DataTable
                    columns={[
                        {name: "Fecha", wrap: true, selector: (row: any) => row.date, width: "100px"},
                        ...columns,
                        {name: "Total", wrap: true, selector: (row: any) => `$${formatCurrency(selectedProject.workload.reduce((acc: number, wk: { name: string }) => acc + row[wk.name].budget, 0))}`, width: "120px"},
                        {name: <div>Total acumulado</div>, wrap: true, selector: (row: any) => `$${formatCurrency(selectedProject.workload.reduce((acc: number, wk: { name: string }) => acc + row[wk.name].accBudget, 0))}`, width: "120px"},
                        {name: "", wrap: true, selector: (row: any) =>
                                <button onClick={() => showWorkloadEditModal(row)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Editar</button
                        >, width: "150px"},

                    ]}
                   data={data}
                />}
            </div>
        </Layout>)
}

export default WorkloadReportPage;