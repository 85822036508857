import Layout from "../../Layout/Layout.component";
import {formatCurrency} from "../../utils/number.utils";
import {AppContext} from "../../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import InfoCard from "../../Components/InfoCard.component";
import {getSpendsByWorkload} from "../../utils/planification.utils";

const WorkloadDashboardPage = () => {

    const { selectedProject, planifications } = useContext(AppContext);
    const [data, setData] = useState<any>({});

    useEffect(() => {
        if (selectedProject && planifications.length > 0) {
            setData(getSpendsByWorkload(planifications, selectedProject));
        }
    }, [planifications, selectedProject]);

    console.log(data)

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl
                    font-bold mb-4">Dashboard Mano de Obra</h1>
                <div className="info-cards">
                    <div>
                        <h2 className="text-xl font-bold my-4">Resumen financiero</h2>
                        <div className="info-card flex gap-2">
                            { selectedProject && <InfoCard
                                key="total"
                                title="Total"
                                content={[
                                    `Presupuesto: $ ${formatCurrency(
                                        selectedProject.workload
                                            .reduce((acc: number, wk: {budget: number}) => acc + wk.budget, 0))}`,
                                    `Gastado: $ ${formatCurrency((Object.values(data) as number[]).reduce((acc: number, val: number) => acc + val, 0))}`,
                                    `Restante: $ ${formatCurrency(( selectedProject.workload
                                        .reduce((acc: number, wk: {budget: number}) => acc + wk.budget, 0) - ((Object.values(data) as number[]).reduce((acc: number, val: number) => acc + val, 0)) ))}`,
                                ]}
                            />}
                            {selectedProject && selectedProject.workload.map((wk: {name: string, budget: number}, index: number) => (
                                <InfoCard
                                    key={index}
                                    title={wk.name}
                                    content={[
                                        `Presupuesto: $ ${formatCurrency(wk.budget)}`,
                                        `Gastado: $ ${formatCurrency(data[wk.name] || 0)}`,
                                        `Restante: $ ${formatCurrency((wk.budget - (data[wk.name] || 0)))}`,
                                    ]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default WorkloadDashboardPage;