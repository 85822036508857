import React from "react";


const SelectFilter: React.FC<{ selectOptions: string[], selected: string, onFilter: (text: string) => void, onClear: () => void }>  = ({ selectOptions ,selected, onFilter, onClear }) => {

    return (
        <div className="flex items-center">
            <select
                value={selected}
                onChange={(e) => onFilter(e.target.value)}
                className="border border-gray-300 p-2 rounded-lg w-64"
            >
                <option value="">Seleccionar...</option>
                {selectOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}

export default SelectFilter;